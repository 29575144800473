.container {
  /* 
  This margin-bottom essentially means 0 margin, 
  the WORB module underneath has negative margin 
  and covers it up (for rounded corners effect) 
  */
  margin-bottom: var(--spacing-normal);

  background-color: var(--color-surface-solid-light);
  padding-inline: 16px; /* magic number from Figma */
  padding-top: var(--spacing-xxxx-loose);
  padding-bottom: var(--spacing-xxxx-loose);
}

.inner {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--spacing-loose);
  margin-inline: auto;
  width: 100%;
  max-width: 510px; /* magic number from Figma */

  @media (width >= 1000px) {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    max-width: unset;
  }

  @media (width >= 1000px) {
    column-gap: var(--spacing-xxx-loose);
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width >= 1000px) {
    align-items: flex-start;
    max-width: 564px; /* magic number from Figma */
  }
}

.kicker {
  --kicker-color: var(--color-text-subtle);

  margin-bottom: var(--spacing-normal);
  text-align: center;

  @media (width >= 1000px) {
    margin-inline: initial;
    text-align: start;
  }
}

.heading {
  margin-bottom: var(--spacing-loose);
  text-align: center;

  @media (width >= 1000px) {
    text-align: start;
  }
}

.text {
  text-align: center;

  @media (width >= 1000px) {
    text-align: start;
  }
}

.button {
  margin-top: var(--spacing-xx-loose);
}

.image {
  display: block;
  margin-inline: auto;
  width: 176px; /* magic number from figma */
  height: auto;

  @media (width >= 1000px) {
    margin-inline: initial;
    margin-bottom: 0;
  }
}
